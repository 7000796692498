html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;

  .sentry-error-embed-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#__next {
  width: 100%;
  height: 100%;
  display: flex;
  background: #fafafb;
}

@media print {
  @page {
    margin: 0.5cm;
  }
}
